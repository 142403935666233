import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { useHasPermission } from 'admin-portal-shared-services';

import { RouteNotPermission, RoutesDefinitions } from '@/components/Router/RoutesDefinitions';
import { Scopes } from '@/consts/Permissions';
import { PATH_BASE } from '@/consts/Routes';
import { useTranslate } from '@/i18n/provider';

export const Router = (): JSX.Element => {
  const { t } = useTranslate();

  const hasNewMFETaskManagementPermission = useHasPermission([
    Scopes.BeesForceNewMFETaskManagementWrite,
  ]);

  const router = createBrowserRouter(
    hasNewMFETaskManagementPermission
      ? RoutesDefinitions
      : RouteNotPermission(t('pages.pageError.permission')),
    {
      basename: PATH_BASE,
      // eslint-disable-next-line camelcase
      future: { v7_relativeSplatPath: true },
    },
  );

  return (
    // eslint-disable-next-line camelcase
    <RouterProvider router={router} future={{ v7_startTransition: true }} />
  );
};
