import { ptBRNotFound } from '@/pages/Page404/i18n/pt-BR';
import { ptBRPageError } from '@/pages/PageError/i18n/pt-BR';
import { ptBRLoading } from '@/pages/PageLoading/i18n/pt-BR';
import { ptBRTaskList } from '@/pages/TaskList/i18n/pt-BR';

export const ptBR = {
  test: {
    translate: 'Traduzir',
    missing: 'Apenas opção em inglês disponível',
  },
  pages: {
    notFound: ptBRNotFound,
    loading: ptBRLoading,
    pageError: ptBRPageError,
    taskList: ptBRTaskList,
  },
};
