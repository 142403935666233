import { es419NotFound } from '@/pages/Page404/i18n/es-419';
import { es419PageError } from '@/pages/PageError/i18n/es-419';
import { es419Loading } from '@/pages/PageLoading/i18n/es-419';
import { esTaskList } from '@/pages/TaskList/i18n/es-419';

export const es419 = {
  test: {
    translate: 'Traducir',
    missing: 'Solo disponible la opción en inglés',
  },
  pages: {
    taskList: esTaskList,
    notFound: es419NotFound,
    pageError: es419PageError,
    loading: es419Loading,
  },
};
